/*input component style*/
#input-search-bar {
    text-align: left;
    font-size: 1.5em;
}
.option {
    text-align: left;
}
@media screen and (min-width: 48em) {
    #input-search-bar {
        text-align: center;
        font-size: 1.5em;
    }
    .option {
        text-align: center;
    }
}

/* bing style
 */
.bing-category {
    text-transform: capitalize;
}

/* fullscreen
 */
.fullscreen {
  display: block;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
}

/* webster style
 */
.webster-pronun {
    color: #225f73;
    letter-spacing: 0.1em;
}
.webster-ins {
    color: #225f73;
    margin-right: 0.3em;
}
.webster-head-word {
    font-size: 2em;
}
.webster-word-type {
    font-size: 1.5em;
    color: #225f73;
    margin-left: 0.3em;
}
.webster-difinition-item {
    color: #225f73;
    margin-left: 0.2em;
    line-height: 1.5em;
    display: block;
}
.webster-difinition-item:first-letter {
    text-transform: uppercase;
}

/* oxford style
 */
.lexical-category {
    color: orange;
    margin-left: 0.6em;
}
.note {
    color: green;
}
.definition {
    margin-left: 0.2em;
    line-height: 1.5em;
    display: block;
}
.definition:first-letter {
    text-transform: uppercase;
}
.definition:after {
    content: ".";
}
.example {
    quotes: '\2018' '\2019';
    margin-left: 0.2em;
    line-height: 1.5em;
    font-weight: 550;
}
.example:before {
    content: open-quote;
}
.example:after {
    content: close-quote;
}
.other-aspect {
    margin-left: 0.6em;
}
.other-aspect-body {
    margin: 1.2em;
    display: inline-block;
}
.other-aspect-body:first-letter {
    text-transform: uppercase;
}
.example-category {
    margin-left: -0.8em;
    color: orange;
}
.normal-font-in-h3 {
    font-size: 0.6em;
}
